$color1: #636363;
$color2: #3a3342;
$color3: #df523f;
$color4: #c89514;
$color5: #c14444;
$color6: #2b9a7f;
$color7: #f4f4f4;
$color8: #f5f0ef;
$color9: lighten($color2, 50%);
$hover4: lighten($color3, 40%);
$color5-35: lighten($color5, 35%);

:root {
    --mf-color-1: #636363;
    --mf-color-2: #3a3342;
    --mf-color-3: #df523f;
    --mf-color-4: #c89514;
    --mf-color-5: #c14444;
    --mf-color-6: #2b9a7f;
    --mf-color-7: #f4f4f4;
    --mf-color-8: #f5f0ef;
    --mf-color-9: #ded9dd;
    // --mf-hover-4: #ded9dd;
}
