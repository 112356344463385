// SCROLLBARS
/* Chromium based */
::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 2px var(--mf-color-3); */
    // background: var();
    /* border-radius: 1px; */
}

::-webkit-scrollbar-thumb {
    /* box-shadow: inset 0 0 2px var(--mf-color-3); */
    background: var(--mf-color-3);
    /* border-radius: 1px; */
}

::-webkit-scrollbar-thumb:hover {
    /* box-shadow: inset 0 0 2px var(--mf-color-3); */
    background: var(--mf-color-3);
    /* border-radius: 1px; */
}

::-webkit-scrollbar-corner {
    background: var(--mf-color-3);
}

/* Firefox */
@-moz-document url-prefix() {
    /* Firefox scrollbar color */
    * {
        scrollbar-color: var(--mf-color-3) var(--mf-color-3) !important;
        scrollbar-width: thin !important;
    }
}