/* You can add global styles to this file, and also import other style files */
@import url('https://cdn.medifit.si/fonts/ubuntu/v15/');
// @import url('https://cdn.medifit.si/fonts/sourcesanspro/v13/');
//'Source Sans Pro'
/* Importing Bootstrap SCSS file. */
// @import 'bootstrap/scss/bootstrap';
@import 'style/variables.scss';
@import 'style/scrollbars.scss';

@import '@meddev/skeleton/src/utils/utils';

// @function toRem($value) {
//     $remValue: ($value / 16) + rem;
//     @return $remValue;
// }

$input-color: $color1;
$input-focus-border-color: $color3;
$input-placeholder-color: lighten($color1,15%);

$btn-border-radius: 0px;
$body-color: $color3;
$border-width: 0.0625rem; //1px
$btn-font-weight: bold;
// @import 'bootstrap/scss/functions';
// @import 'bootstrap/variables';

// $theme-colors: (
//     danger: $color5,
// );

@import '@meddev/skeleton/src/vendors/vendors';
@import '@meddev/skeleton/src/base/layout';
@import '@meddev/skeleton/src/layout/layout';
@import '@meddev/skeleton/src/components/compontents';
@import '@meddev/icon-set/css/mf-font-set.css';
@import 'ngx-toastr/toastr';
@import 'ngx-toastr/toastr-bs4-alert';
@import '@ng-select/ng-select/themes/default.theme.css';

@import '@swimlane/ngx-datatable/index.css';
@import '@swimlane/ngx-datatable/themes/material.css';
@import '@swimlane/ngx-datatable/themes/bootstrap.css';
@import '@swimlane/ngx-datatable/assets/icons.css';

html,
body {
    font-family: 'Ubuntu', apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    color: $color1;
}

@include generator('primary', $color3);
@include generator('white', white);
@include generator('brand', $color4);
@include generator('secondary', $color1);
@include generator('accent', $color2);
@include generator('danger', $color5);
@include inputGenerator('brand', $color2);
@include gradientGenerator('primary', 350deg, #d28475, #0a4251, -7.5%, 48.55%, #e2907d);

// .mf-arrow{

// }

// .mf-arrow-2 {
//     position: relative;
//     background-color: blue;
//     width: 20px;
// }

.mf-btn-arrow {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 40px;
    // margin-left: 40px;
    // margin-right: 40px;
    position: relative;
    line-height: 2.5em;
    padding-left: 0.6em;
    // padding-right: 0.6em;
    background: $color3;
    color: white;
    font-weight: bold;
    &:after {
        // triangle hover color
        border-left: 20px solid $color3;
    }
}

.mf-btn-arrow:after {
    // the triangle
    content: '';
    position: absolute;
    border-bottom: 20px solid transparent;
    border-top: 20px solid transparent;
    height: 0px;
    width: 0px;
    margin-right: -20px;
    right: 0;
}

.mf-btn-arrow:hover,
.mf-btn-arrow:active {
    background: $color1;
    color: white;
    &:after {
        // triangle hover
        border-left: 20px solid $color1;
    }
}

#toast-container {
    .toast-success {
        background-color: $color6;
    }
    .toast-error {
        background-color: $color5;
    }
}
.mf-tooltip {
    .tooltip-inner {
        border-radius: 0.25rem;
        background-color: var(--mf-color-1);
    }

    &.bs-tooltip-bottom {
        .arrow:before {
            border-bottom-color: var(--mf-color-1);
        }
    }

    &.bs-tooltip-top {
        .arrow:before {
            border-top-color: var(--mf-color-1);
        }
    }

    &.bs-tooltip-left {
        .arrow:before {
            border-left-color: var(--mf-color-1);
        }
    }
    &.bs-tooltip-right {
        .arrow::before {
            border-right-color: var(--mf-color-1);
        }
    }
}

.ng-select {
    &.is-invalid {
        .ng-select-container {
            background-color: #e9bec0;
            border-color: $color3;
            animation: blinkBackground linear 0.4s 2 forwards;
        }
    }
    &.ng-select-sm {
        .ng-select-container {
            height: calc(1.5em + 0.4rem);
            min-height: 10px;
          }
          
          .ng-select-single .ng-select-container {
            height: calc(1.5em + 0.4rem);
          }
    }
    &.ng-select-opened {
        .ng-select-container {
            border: 1px solid $color3;
        }

        .ng-arrow-wrapper {
            .ng-arrow {
                border-color: transparent transparent $color3;
            }
        }
    }
    .ng-select-container {
        border-radius: 0;
        border: 1px solid $color1;
    }

    .ng-arrow-wrapper {
        .ng-arrow {
            border-color: $color1 transparent transparent;
        }
    }
}
.ng-dropdown-panel.ng-select-bottom {
    border-top: 1px solid $color3;
}

.mf-transparent-popup {
    .modal-dialog {
        margin-top: 0;
        margin-bottom: 0;
        .modal-content {
            background-color: transparent; // border: 1px solid white;
            box-shadow: none;
            border: none;
            .modal-footer {
                padding: 0px;
                border: none;
            }
        }
    }
}

.modal-full {
    max-width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.modal-screen {
    max-width: 100%;
    max-height: 100vh;
    background-color: red;
}

.ngx-datatable.bootstrap {
    //classic
    .datatable-body {
        .datatable-body-row {
            &.active {
                color: $color1;
            }
            &.datatable-row-even,
            &.datatable-row-odd {
                background-color: white;
                &:hover {
                    background-color: $hover4;
                    cursor: pointer;
                }
            }
        }
    }
    .datatable-footer {
        background-color: $color7;
        color: $color1;
        .datatable-pager {
            .pager li a {
                color: $color1;
            }
            li:not(.disabled).active a {
                color: white;
            }
        }
    }
}
.ngx-datatable.bootstrap.table--dark {
    //dark-mode
    .datatable-header-cell-label.draggable,
    .empty-row,
    .sort-btn {
        color: white;
    }
    .datatable-footer {
        background-color: darken($color2,5%);
        color: white;
        .datatable-pager {
            .pager li a {
                color: white;
            }
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h5 {
    color: $color3;
}
// PDFJS COMPONENT
.ng2-pdf-viewer-container {
    overflow: hidden;
    overflow-x: hidden !important;
}

.mf-pointer {
    cursor: pointer;
}
.dropdown-menu {
    max-height: 300px;
    max-width: 700px;
    overflow-y: auto;
    button.active {
        background-color: $color3 !important;
    }
}

ngb-datepicker {
    .ngb-dp-weekday,
    .ngb-dp-navigation-chevron {
        color: $color3;
    }
}
ngb-modal-backdrop {
    z-index: 1001!important;
}

input:enabled[readonly] {
    //daterangepicker disable custom input
    background-color: white !important;
}

.m-btn {
    border-radius: 12px;
        &--hover{
            &-brand{
                &:hover{
                border-radius: 12px;
                border-color: $color3;
                color: white;
                background-color: $color3;
            }
        }
    }
}
.btn {
    &--small {
      padding: 0.5em;
      margin: 0.85em;  
      font-size: 0.88em;
    }
}

.m-badge {
    border-radius: 12px;
    background: #eaeaea;
    color: #444;
    font-size: 0.7rem;
    line-height: 14px;
    min-height: 14px;
    min-width: 14px;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    padding: 0 2px;

    &.m-badge--secondary {
        background-color: #878480;
        color: white;
        &--outline {
            color: #636363;
            background: none;
            border-width: 2px;
            border-style: solid;
            border-color: #878480;
        }
    }

    &.m-badge--danger {
        background-color: $color5;
        color: white;
        &--outline {
            color: #636363;
            background: none;
            border-width: 2px;
            border-style: solid;
            border-color: $color5;
        }
    }

    &.m-badge--warning {
        background-color: $color3;
        color: white;
        &--outline {
            color: #636363;
            background: none;
            border-width: 2px;
            border-style: solid;
            border-color: $color3;
        }
    }

    &.m-badge--success {
        background-color: #20c0b6;
        color: white;
        &--outline {
            color: #636363;
            background: none;
            border-width: 2px;
            border-style: solid;
            border-color: #20c0b6;
        }
    }

    &.m-badge--accent {
        background-color: #f0b966;
        color: white;
        &--outline {
            color: #636363;
            background: none;
            border-width: 2px;
            border-style: solid;
            border-color: #f0b966;
        }
    }

    &.m-badge--dark {
        background-color: black;
        color: white;
        &--outline {
            color: #636363;
            background: none;
            border-width: 2px;
            border-style: solid;
            border-color: black;
        }
    }
    &.m-badge--primary {
        background-color: $color3;
        color: white;
        &--outline {
            color: #636363;
            background: none;
            border-width: 2px;
            border-style: solid;
            border-color: $color3;
        }
    }
}
.intercom-launcher-frame {
    background: #df523f !important;
}
.mf-h-input {
    position: absolute;
    left: -2000px;
}
.form-check-inp {
    display: flex;
    align-items: center;
    margin-bottom: 0.125rem;

    &:hover:not(.disabled) > label {
            color: $color3;
        }
    
    input:disabled {
        cursor: default !important;
        border-color: rgba(118, 118, 118, 0.3) !important;
    }
    &.mf-big {
        input[type='radio'] {
            margin-left: 5px;
            margin-right: 10px;
            width: 1.25em;
            height: 1.2em;
            &::before {
                width: 0.58em;
                height: 0.58em;
            }
        }
        input[type='checkbox'] {
            margin-left: 5px;
            margin-right: 10px;
            width: 1.3em;
            height: 1.3em;
            &::before {
                width: 0.68em;
                height: 0.68em;
            }
        }
    }
    input[type='radio'] {
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        margin: 0;
        margin-right: 0.5em;
        font: inherit;
        color: $secondary;
        width: 1.15em;
        height: 1.15em;
        border: 0.18em solid $secondary;
        border-radius: 50%;

        display: grid;
        place-content: center;

        &::before {
            content: '';
            width: 0.65em;
            height: 0.65em;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $color3;
        }

        &:checked::before {
            transform: scale(1);
        }
    }
    input[type='checkbox'] {
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        margin: 0;
        margin-right: 0.5em;
        font: inherit;
        color: $secondary;
        width: 1.15em;
        height: 1.15em;
        border: 0.2em solid $secondary;

        display: grid;
        place-content: center;

        &::before {
            content: '';
            width: 0.65em;
            height: 0.65em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $color3;
        }

        &:checked::before {
            transform: scale(1);
        }
    }
}
.form-check-inp {
    input[type='radio']:checked ~ label {
        color: $color3;
    }
    input[type='checkbox']:checked ~ label {
        color: $color3;
    }
}

.form-control.is-invalid {
    // background-color: $color5-35;
    // animation: blinkBackground 0.5s ease-in-out;
    animation: blinkBackground linear 0.4s 2 forwards;
}

@keyframes blinkBackground {
    0% {
        background-color: white;
    }
    50% {
        background-color: $color5;
    }
    100% {
        background-color: $color5-35;
    }
    0% {
        background-color: white;
    }
    50% {
        background-color: $color5;
    }
    100% {
        background-color: $color5-35;
    }
}
.ng-select {
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.0625rem solid #ced4da;
    border-radius: 0.25rem;
    line-height: 1.5;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: lighten($color1,15%);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    background-color: $color3;
    color: white;
}
.cursor-pointer {
    cursor:pointer;
}
.ng-select.ng-select-single .ng-select-container {
    border: none;
}

.file-upload {
    outline-offset: 0 !important;
    padding: 1rem !important;
    outline: #ebedf2 dashed 1px !important;

    &.list-visible {
        .upload-input {
            position: relative;
            top: 1rem !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            margin-left: 0 !important;
        }
    }

    .file-info {
        border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
        padding: 5px 0 5px 0;

        &:last-child {
            border: none;
            padding-bottom: 0px;
        }

        a {
            cursor: pointer;
        }
    }
}
//overwrite ngb-pagination
.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -0.0625rem;
    line-height: 1.25;
    color: $color2;
    background-color: white;
    border: 0.0625rem solid #dee2e6;
    &:hover {
        color: $color2;
    }
}
.page-item.active .page-link {
    color: $color7;
    background-color: $color3;
    border-color: $color3;
}
.shadow {
    &--dark {
        box-shadow: -8px 0px 8px -2px #777;
    }
}
.visually-hidden {
    display: none;
}
.la-1_5x {
    font-size: 1.5em;
}

.font-sm {
    font-size: 0.75em; 
}

.referral-container {
    height: 100%;
}

.ps-content {
    height: 100%;
}

.scrollable-container {
    position: relative;
}

.scrollable-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 -10px 10px -10px rgba(255, 255, 255, 0.8),
                inset 0 10px 10px -10px rgba(255, 255, 255, 0.8);
    z-index: 1;
    pointer-events: none;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
  }